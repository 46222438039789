import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ScrollSpy } from "bootstrap"

import  Circle  from '../assets/ds-circle.svg';
import  Arrow  from '../assets/arrow.svg';
import  Small  from '../assets/arrow-small.svg';


const IndexPage = () => (
  <Layout> 
    <Seo title="digital smash" />
    
      <section className="px-md-2 py-md-2 my-md-2 px-2 py-2 my-2">
        <div className="row">
          <div className="col-md-8">
            <h1>
              <span className="top">Web</span>
              <span className="sub">& Digital</span>
            </h1>
            <div className="col-md-8">
              <p className="lead mb-4">Each solution relies on a dynamic approach between experimentation, technology and creativity in which the right emotional drive allows the achievement of the goal.</p>
            </div>
            <div className="col-md-12">
              <p className="mb-5">
                Through an extremely functional design, attentive to usability and ease of browsing we make every user of our projects live a unique and innovative experience.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <ul className="list-unstyled">
                <li><Small />User Experience</li>
                <li><Small />Interface Design</li>
                <li><Small />UX Research</li>
                <li><Small />Screendesign</li>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <ul className="list-unstyled">
                <li><Small />Frontend</li>
                <li><Small />Jamstack</li>
                <li><Small />Web App</li>
                <li><Small />Responsive Design</li>
              </ul>
            </div>
            <div className="col-12 col-md-3">
              <ul className="list-unstyled">
                <li><Small />Agile Approach</li>
                <li><Small />Scrum Method</li>
                <li><Small />Projekt Management</li>
                <li><Small />Storytelling</li>
              </ul>
            </div>
          </div>
          
          <div className="teaser d-none d-sm-block">
            <a href="#contact">
            <span className="tease-text">
              get in touch
            </span>
            </a>
            <div className="circle">
              <a href="mailto:info@digital-smash.de">
                <Circle className="u--rotate" />
              </a>
            </div>
            <div className="arrow">
              <a href="mailto:info@digital-smash.de">
              <Arrow className=""/>
              {/* info@digital-smash.de */}
              </a>
            </div>
          </div>
          
        </div>
      </section>

  </Layout>

)
export default IndexPage
